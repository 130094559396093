<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Valores Faltantes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Valores Faltentes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMA ACTUALIZACIÓN PÁGINA-->
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-warning"
                        @click="openModal('Crear', null)"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.valoresFaltantes.create'
                          )
                        "
                      >
                        <i class="fas fa-coins"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
              <div class="card-body table-responsive p-0">
                <div class="row m-2">
                  <!-- Card Proyecciones -->
                  <div class="col-md-6">
                    <label> Proyeccion</label>
                    <table
                      id="users"
                      class="table table-bordered table-striped table-hover table-sm"
                    >
                      <thead>
                        <tr>
                          <th>
                            Id<input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtros.id1"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Precio<input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtros.precio1"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Mes
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.mes1"
                              @change="getIndex()"
                            >
                              <option value="">Todos...</option>
                              <option
                                v-for="mes in listasForms.mes"
                                :key="mes.numeracion"
                                :value="mes.numeracion"
                              >
                                {{ mes.descripcion }}
                              </option>
                            </select>
                          </th>

                          <th>
                            Año<input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="filtros.año1"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Tipo Producto
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="producto_proyeccion"
                              placeholder="nombre"
                              label="producto_sitio"
                              :options="listasForms.tipoProducto"
                              @input="selectProductoP()"
                              :append-to-body="true"
                            ></v-select>
                          </th>

                          <th>Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="proyeccion in proyeccion.data"
                          :key="proyeccion.id"
                        >
                          <td style="width: 150px">{{ proyeccion.id }}</td>
                          <td>{{ proyeccion.precio }}</td>
                          <td>{{ getMesDescripcion(proyeccion.mes) }}</td>
                          <td>{{ proyeccion.año }}</td>
                          <td class="text-center">
                            <span
                              v-for="producto in proyeccion.productos"
                              :key="producto.id"
                              class="badge bg-navy"
                              style="display: inline-block; margin: 2px;"
                            >
                              {{ producto.nombre }} -
                              {{
                                producto.sitio ? producto.sitio.nombre : null
                              }}
                            </span>
                          </td>
                          <td style="width: 50px">
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                @click="
                                  openModal(
                                    'Editar Valor Proyeccion',
                                    proyeccion
                                  )
                                "
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.valoresFaltantes.create'
                                  )
                                "
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- Card Real -->
                  <div class="col-md-6">
                    <label> Valor real</label>
                    <table
                      id="users"
                      class="table table-bordered table-striped table-hover table-sm"
                    >
                      <thead>
                        <tr>
                          <th>
                            Id<input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtros.id2"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Precio<input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtros.precio2"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Mes
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.mes2"
                              @change="getIndex()"
                            >
                              <option value="">Todos...</option>
                              <option
                                v-for="mes in listasForms.mes"
                                :key="mes.numeracion"
                                :value="mes.numeracion"
                              >
                                {{ mes.descripcion }}
                              </option>
                            </select>
                          </th>

                          <th>
                            Año<input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="filtros.año2"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Tipo Producto
                            <v-select
                              v-model="producto_real"                              
                              label="producto_sitio"
                              :options="listasForms.tipoProducto"
                              placeholder="nombre"
                              @input="selectProductoR()"
                            >
                            </v-select>
                          </th>
                          <th>Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="real in real.data" :key="real.id">
                          <td style="width: 150px">{{ real.id }}</td>
                          <td>{{ real.precio }}</td>
                          <td>{{ getMesDescripcion(real.mes) }}</td>
                          <td>{{ real.año }}</td>
                          <td class="text-center">
                            <span
                              v-for="producto in real.productos"
                              :key="producto.id"
                              class="badge bg-navy"
                              style="display: inline-block; margin: 2px;"
                            >
                              {{ producto.nombre }} -
                              {{
                                producto.sitio ? producto.sitio.nombre : null
                              }}
                            </span>
                          </td>

                          <td style="width: 50px">
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                @click="openModal2('Editar Valor Real', real)"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.valoresFaltantes.create'
                                  )
                                "
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- Modal Crear / Editar -->
              <div class="modal" ref="precioModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg p-0 pt-1" role="document">
                  <div class="modal-content">
                    <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                      <h5 class="modal-title text-white">
                        {{ modal.accion }}
                      </h5>
                      <button
                        type="button"
                        class="close text-white"
                        @click="limpiarModal()"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="close-modal"
                        ref="closeModal"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-3">
                            <label for="Mes">Mes:</label>
                            <select
                              v-model="nuevoProyeccion.mes"
                              class="form-control form-control"
                              :class="[
                                $v.nuevoProyeccion.mes.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="mes in listasForms.mes"
                                :key="mes.numeracion"
                                :value="mes.numeracion"
                              >
                                {{ mes.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label for="precio">Precio:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="precio"
                              v-model="nuevoProyeccion.precio"
                              :class="
                                $v.nuevoProyeccion.precio.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                          <div class="col-md-3">
                            <label for="fecha">Año:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="fecha"
                              v-model="nuevoProyeccion.año"
                              :class="
                                $v.nuevoProyeccion.año.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                          <div class="col-md-7">
                            <label for="productos">Tipo Producto:</label>
                            <v-select
                              v-model="productos"
                              label="producto_sitio"
                              :options="listasForms.tipoProducto"
                              placeholder="nombre"
                              multiple
                              @input="selectProductos()"
                              :class="[
                                productos && productos.length > 0
                                  ? 'border border-success'
                                  : 'border border-danger',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                            >
                            </v-select>
                          </div>
                          <div class="col-md-5">
                            <label for="productos">Tipo Valor:</label>

                            <select
                              v-model="nuevoProyeccion.tipo_valor"
                              class="form-control form-control"
                              :class="[
                                $v.nuevoProyeccion.tipo_valor.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="tipoValor in listasForms.tipoValor"
                                :key="tipoValor.numeracion"
                                :value="tipoValor.numeracion"
                              >
                                {{ tipoValor.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        @click="limpiarModal()"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="close-modal"
                        ref="closeModal"
                        type="button"
                        class="btn btn-secondary"
                      >
                        Cerrar
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="save()"
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="float-left" v-if="proyeccion.total">
                  <p>
                    Mostrando del <b>{{ proyeccion.from }}</b> al
                    <b>{{ proyeccion.to }}</b> de un total:
                    <b>{{ proyeccion.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p></p>
                </div>
                <pagination
                  :data="proyeccion"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right m-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "ValoresFaltantes",
  components: {
    pagination,
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      modal: [],
      accion: "",
      filtros: {
        id1: null,
        id2: null,
        precio1: null,
        año1: null,
        precio2: null,
        año2: null,
        mes1: null,
        mes2: null,
        productos_valores1: null,
        productos_valores2: null,
      },
      proyeccion: {},
      real: {},
      productos: [],
      productos_valores: {},
      producto_proyeccion: {},
      producto_real: {},
      listasForms: {
        monedas: [],
        tipoProducto: [],
        mes: [],
        tipoValor: [],
      },
      form: {
        file: null,
      },
      nuevoProyeccion: {
        mes: null,
        año: null,
        precio: null,
        productos: null,
        productos_valores: null,
        tipo_valor: null,
        accion: null,
      },

      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    nuevoProyeccion: {
      fecha: {
        required,
      },
      precio: {
        required,
      },
      moneda: {
        required,
      },
      mes: {
        required,
      },
      año: {
        required,
      },
      productos: {
        required,
      },
      tipo_valor: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/valoresFaltantes?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.proyeccion = response.data.proyeccion;
          this.real = response.data.real;
          this.cargando = false;
        });
    },

    getMesDescripcion(mesId) {
      const mes = this.listasForms.mes.find((m) => m.numeracion === mesId);
      return mes ? mes.descripcion : "Mes no encontrado";
    },

    selectProductoP() {
      if (this.producto_proyeccion != null) {
        this.filtros.productos_valores1 = this.producto_proyeccion.id;
      } else {
        this.filtros.productos_valores1 = null;
      }
      this.getIndex();
    },

    selectProductoR() {
      if (this.producto_real != null) {
        this.filtros.productos_valores2 = this.producto_real.id;
      }else{
        this.filtros.productos_valores2 = null;
      }
      this.getIndex();
    },

    // Método para abrir la modal
    openModal(accion, proyeccion) {
      this.modal.accion = accion;
      if (accion == "Editar Valor Proyeccion") {
        this.productos = this.listasForms.tipoProducto.filter((tp) =>
          proyeccion.productos.map((p) => p.id).includes(tp.id)
        );

        this.nuevoProyeccion = {
          mes: proyeccion.mes,
          precio: proyeccion.precio,
          moneda: proyeccion.moneda,
          año: proyeccion.año,
          productos: proyeccion.productos.map((p) => p.id),
          tipo_valor: proyeccion.tipo_valor,
          accion: 2,
        };
        this.modal.id = proyeccion.id;
      } else {
        this.nuevoProyeccion = {
          mes: null,
          precio: null,
          productos_valores: null,
          año: null,
          productos: null,
          tipo_valor: null,
          accion: 1,
        };
        this.modal.id = null;
      }
      this.$refs.precioModal.classList.add("show");
      this.$refs.precioModal.setAttribute("style", "display: block");
    },

    openModal2(accion, real) {
      this.modal.accion = accion;
      if (accion == "Editar Valor Real") {
        this.productos = real.productos;
        this.nuevoProyeccion = {
          mes: real.mes,
          precio: real.precio,
          moneda: real.moneda,
          año: real.año,
          productos: this.productos.map((p) => p.id),
          tipo_valor: real.tipo_valor,
          accion: 2,
        };

        this.modal.id = real.id;
      } else {
        this.nuevoProyeccion = {
          mes: null,
          precio: null,
          productos_valores: null,
          año: null,
          productos: null,
          accion: 1,
        };
        this.productos = null;
        this.modal.id = null;
      }
      this.$refs.precioModal.classList.add("show");
      this.$refs.precioModal.setAttribute("style", "display: block");
    },

    selectProductos() {
      this.nuevoProyeccion.productos = this.productos.map((p) => p.id);
    },

    // Método para cerrar la modal y limpiar el formulario
    limpiarModal() {
      this.$refs.precioModal.classList.remove("show");
      this.$refs.precioModal.removeAttribute("style");
      this.nuevoProyeccion = {
        mes: null,
        precio: null,
        productos_valores: null,
        año: null,
        productos: null,
        accion: null,
      };
      this.productos = null;
    },

    limpiarModal2() {
      this.$refs.importModal.classList.remove("show");
      this.$refs.importModal.removeAttribute("style");
      this.nuevoProyeccion = {
        mes: null,
        precio: null,
        productos_valores: null,
        año: null,
        productos: null,
        accion: null,
      };
      this.productos = null;
    },

    save() {
      //     if (!this.nuevoPrecio.$invalid) {
      let data = {
        id: this.modal.id,
        mes: this.nuevoProyeccion.mes,
        precio: this.nuevoProyeccion.precio,
        productos_valores: this.nuevoProyeccion.productos,
        año: this.nuevoProyeccion.año,
        tipo_valor: this.nuevoProyeccion.tipo_valor,
        accion: this.nuevoProyeccion.accion,
      };
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/valoresFaltantes",
        data: data,
      })
        .then((response) => {
          this.$refs.closeModal.click();
          this.nuevoProyeccion = {};
          this.getIndex(this.page);
          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title:
              "El registro de año:" +
              this.nuevoProyeccion.año +
              " ya se encuentra creado, vualeva a intentarlo...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
      // }
    },

    getMonedas() {
      axios.get("/api/lista/134").then((response) => {
        this.listasForms.monedas = response.data;
      });
    },

    getTipoProducto() {
      axios
        .get("/api/hidrocarburos/Productos/lista", {
          params: {
            sitio_producto: 1,
          },
        })
        .then((response) => {
          this.listasForms.tipoProducto = response.data;
        });
    },

    getMeses() {
      axios.get("/api/lista/152").then((response) => {
        this.listasForms.mes = response.data;
      });
    },

    getTipoValor() {
      axios.get("/api/lista/208").then((response) => {
        this.listasForms.tipoValor = response.data;
      });
    },
  },

  mounted() {
    this.getTipoProducto();
    this.getTipoValor();
    this.getIndex();
    this.getMonedas();
    this.getMeses();
  },
};
</script>
